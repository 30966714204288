.material-symbols-rounded {
    @include material-symbol-family;

    &.size-16 {
        font-size: 1.125rem;
    }

    &.size-20 {
        font-size: 1.25rem;
    }

    &.size-28 {
        font-size: 1.75rem;
    }
}