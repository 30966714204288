/* utils */
@import "../utils/utils";
@import "../common/reset";
@import "../common/fonts";
@import "../components/material-symbols";

:host {
    position: relative !important;
    width: 100%;
    display: block;
}

.container {
    background-color: theme-color('white');
    border-radius: 0.25rem;
    color: theme-color('core-blue');
    cursor: default;
    display: inline-block;
    font-family: theme('font-family', 'body');
    font-size: theme('font-size', 'small');
    height: 0;
    overflow: hidden;
    pointer-events: all;
    position: absolute;
    transform: scale(0);
    transform-origin: top left;
    transition: transform .3s ease-out;
    inset: calc((var(--y) * 1px) + 0.5rem) auto auto calc(var(--x) * 1px) !important;

    &.flipped {
        inset: calc((var(--y) * 1px) + 0.5rem) calc(var(--x) * 1px) auto auto !important;
    }
}

.container.calc {
    height: auto;
    transform: none;
    transition: none;
    visibility: hidden;
}

.container.show {
    height: auto;
    transform: scale(1);
    border: 1px solid theme-color('border-blue');
}

.container > header {
    padding: 0.75rem 1.5rem;
    font-size: theme('font-size', 'medium');
    font-weight: 500;
    border-block-end: 1px solid theme-color('border-blue');
    margin-block-end: 0.5rem;
}

.container > footer {
    padding: 1.5rem;
}

.container > footer .footer-buttons {
    gap: 0.5rem;
    display: flex;
    justify-content: flex-end;
}

.container > footer .footer-buttons > button {
    background-color: theme-color('light-teal');
    border-radius: 0.25rem;
    border-width: 0;
    color: theme-color('core-blue');
    font-size: theme('font-size', 'x-small');
    font-weight: 500;
    line-height: 1.5;
    text-decoration: none;
    padding: 0.75rem;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.container > footer .footer-buttons > button.apply-button {
    background-color: theme-color('light-teal');
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    justify-content: center;

    &:not(:disabled):focus,
    &:not(:disabled):hover {
        background-color: theme('color', 'light-teal-hover', 'custom');
    }

    &:not(:disabled):active {
        background-color: theme('color', 'light-teal-active', 'custom');
    }
}

.container > footer .footer-buttons > button.cancel-button {
    background-color: theme-color('white');
    color: inherit;
    position: absolute;
    inset: 0.5rem 0.5rem auto auto;
    line-height: 0;
    padding: 0.5rem;
}

.calendars:not(.grid-1) .calendar > .header .month-name {
    order: 2;
    text-align: center;
}

.calendars:not(.grid-1) .calendar > .header .previous-button {
    order: 1;
}

.calendars:not(.grid-1) .calendar > .header .next-button {
    order: 3;
}

.calendar > .header {
    gap: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendar > .header button {
    background-color: transparent;
    border: 0;
    border-radius: 0.25rem;
    justify-content: center;
    padding: 0.5rem;
    line-height: 0;
    color: inherit;
}

.calendar > .daynames-row,
.calendar > .days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.25rem 0;
}

.calendar > .daynames-row > .day,
.calendar > .daynames-row > .dayname,
.calendar > .days-grid > .day,
.calendar > .days-grid > .dayname {
    align-items: center;
    cursor: default;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    justify-content: center
}

.calendar > .daynames-row > .dayname {
    font-size: 0;
    padding: 1rem 0;
    display: block;
    text-align: center;
    line-height: 1;

    &::first-letter {
        color: theme-color('grey');
        font-size: 1rem;
    }
}

.calendar > .days-grid > .day {
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
}

.calendar > .days-grid > .day.today {
    &::after {
        content: '';
        border: 1px solid;
        border-radius: 50%;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 5;
    }
}

.calendar > .days-grid > .day.selected {
    background-color: theme-color('core-blue');
    color: theme-color('white');
}

@include bp($max: lg) {
    .container {
        transform: scaleY(0) !important;
        transform-origin: bottom center !important
    }

    .container.show {
        transform: scaleY(1) !important;
        border: 0;
        inset: calc(var(--y) * 1px) calc(var(--x) * 1px) auto auto !important;
    }

    .container {
        width: 100%;
    }

    .calendars {
        grid-template-columns: repeat(1, 1fr);
    }

    .calendars .calendar {
        box-sizing: border-box;
        width: 100%;
    }

    .calendars .calendar:nth-child(n+2) {
        display: none;
    }
}

.container.preset-plugin > main {
    display: flex;
    padding: 0 0.5rem;
    gap: 0.5rem;

    @include bp($max: lg) {
        justify-content: center;
    }
}

.preset-plugin-container {
    display: grid;
    order: 1;
    width: 7.5rem;
    gap: 0.25rem;
    align-content: start;
}

.preset-plugin-container > button {
    background: none;
    border: 0;
    border-radius: 0.25rem;
    padding: 0.5rem;
    color: inherit;
    font-size: 0.875rem;
    font-weight: 500;

    &:focus,
    &:hover {
        background-color: theme-color('pebble');
    }

    &:active {
        background-color: theme-color('stone');
    }
}


.container.range-plugin .calendar > .days-grid > .day {
    position: relative;
}

.container.range-plugin .calendar > .days-grid > .day.in-range:last-of-type {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.container.range-plugin .calendar > .days-grid > .day.in-range {
    background-color: theme-color('light-teal');
    border-radius: 0;
}

.container.range-plugin .calendar > .days-grid > .day.end,
.container.range-plugin .calendar > .days-grid > .day.start {
    background-color: theme-color('light-teal');
    color: theme-color('white');
    z-index: 1;
}

.container.range-plugin .calendar > .days-grid > .day.start {
    border-radius: 1.5rem 0 0 1.5rem;
}

.container.range-plugin .calendar > .days-grid > .day.start:after,
.container.range-plugin .calendar > .days-grid > .day.end:after {
    content: "";
    pointer-events: none;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: theme-color('core-blue');
    border-radius: 50%;
    z-index: -1;
}

.container.range-plugin .calendar > .days-grid > .day.start.flipped,
.container.range-plugin .calendar > .days-grid > .day.end {
    border-radius: 0 1.5rem 1.5rem 0;
}

.container.range-plugin .calendar > .days-grid > .day.end.flipped {
    border-radius: 1.5rem 0 0 1.5rem;
}

.container.range-plugin .calendar > .days-grid > .day.start.end {
    border-radius: 50%;
}

.container.range-plugin .calendar > .days-grid > div:not(.day) + .day.in-range {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.container.range-plugin .calendar > .days-grid > div:nth-child(7n).in-range {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.container.range-plugin .calendar > .days-grid > div:nth-child(7n+1).in-range {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.container.preset-plugin main.preset-left > .calendars {
    order: 2;
}

.container.amp-plugin .calendars .calendar > .header .month-name {
    align-items: center;
    gap: 0.25rem;
    display: flex;
    justify-content: center;
    flex: 1;
}

.container.amp-plugin .calendars .calendar > .header .reset-button {
    order: 4;
}

.container.amp-plugin .calendars.calendars:not(.grid-1) .calendar:last-child > .header .reset-button {
    font-size: 0.875rem;
    background: theme-color('light-teal');
    padding: 0.5rem;
    display: block;
    line-height: 1.5;
    font-weight: 500;

    &:focus,
    &:hover {
        background-color: theme('color', 'light-teal-hover', 'custom');
    }

    &:active {
        background-color: theme('color', 'light-teal-active', 'custom');
    }
}

.container .range-plugin-tooltip {
    display: none;
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}