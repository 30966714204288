/*
 * This is Andy Bell's modern CSS reset
 * Modern browsers don't need the normalize.css heavy approach
 * @link https://hankchizljaw.com/wrote/a-modern-css-reset/
 */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

[hidden],
[x-cloak] {
  display: none !important;
}

/* Remove default padding */
ul[class]:not(.wp-block-list),
ol[class]:not(.wp-block-footnotes):not(.wp-block-list) {
  padding: 0;
}

/* Remove default margin */
body,
header :where(h1, h2, h3, h4, p, ul, ol, li, figure, figcaption, blockquote, dl, dd),
footer :where(h1, h2, h3, h4, p, ul, ol, li, figure, figcaption, blockquote, dl, dd) {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  background-color: white;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class]:not(.wp-block-list),
ol[class]:not(.wp-block-footnotes):not(.wp-block-list) {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  height: auto;
}

/* Natural flow and rhythm in articles by default
 * This is opinionated and intended to make working with component-based systems easier
 * If you don't know about the lobotomised owl, search that term for the ALA article
 * @link https://24ways.org/2018/managing-flow-and-rhythm-with-css-custom-properties/
 */
article > * + * {
  // margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

button {
  cursor: pointer;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Site wide Block margin */
:is(body .is-layout-constrained) > * {
  margin-block-start: theme('spacing', 40);

  @include bp(md) {
    margin-block-start: theme('spacing', 60);
  }
}

.has-background {
  & + & {
    margin-block-start: 0;
  }
}