@mixin material-symbol-family() {
    font-family: "Material Symbols Rounded";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    width: 1ch;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased; // Support for all WebKit browsers
    -moz-osx-font-smoothing: grayscale; // Support for Firefox
    text-rendering: optimizeLegibility; // Support for Safari and Chrome
    font-feature-settings: 'liga'; // Support for IE
    font-variation-settings:
        'FILL' var(--td--icon--fill, 0),
        'wght' var(--td--icon--wght, 400),
        'GRAD' var(--td--icon--grad, 0),
        'opsz' var(--td--icon--opsz, 48);
}

@mixin material-symbol($icon) {
    @include content-alt-text($icon);
    @include material-symbol-family;
}