/*
 * Generate a media query based on convenient names such as sm, md , lg. The
 * names are set in a $breakpoints-map.
 *
 * Usage:
 *
 * @include bp(sm); // @media only screen and (min-width: 600px)
 *
 * @include bp($max: sm);  // @media only screen and (max-width: 600px)
 *
 * @include bp(sm, md);  // @media only screen and (min-width: 600px) and (max-width: 900px)
 *
 * @include bp(sm, $type: print);  // @media print and (min-width: 600px)
 *
 * @include bp(sm, md, print);  // @media print and (min-width: 600px) and (max-width: 900px)
 */

@mixin bp($min: null, $max: null, $type: only screen, $map: $breakpoint-map) {
    // get the value/s from the $map
    $min-value: if(map-get($map, $min), map-get($map, $min), $min);
    $max-value: if(map-get($map, $max), map-get($map, $max), $max);

    // build the media query
    $min: if($min, "(width >= #{$min-value})", null);
    $max: if($max, "(width < #{$max-value})", null);
    $and: if($min and $max, " and ", null);

    // output the media query with the @content
    @media #{$type} and #{$min $and $max} {
        @content;
    }
}